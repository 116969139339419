<template>
  <div
    class="relative grid grid-cols-[2.5rem_1fr] grid-rows-[1fr_auto_auto] gap-x-4"
  >
    <OrganizationCardDense
      v-if="commentOrg"
      :org="commentOrg"
      plain
      class="col-span-2 !py-0 mr-8"
    >
      <template
        #postDisplayName
        v-if="highlightAuthorIds?.includes(commentOrg.id)"
      >
        <span
          class="text-xs uppercase font-bold text-white bg-nami-comi-blue px-1.5 rounded-full"
        >
          {{ highlightAuthorTag || "creator" }}
        </span>
      </template>
      <template #subtext>
        <p :title="creationReadableDate" class="text-neutral-500">
          {{ creationTimeAgo }}
        </p>
        <span
          v-if="comment.attributes.updatedAt"
          class="text-neutral-500 ml-1"
          :title="
            'Modified: ' + new Date(comment.attributes.updatedAt).toString()
          "
        >
          ({{ $t("components.feedCommentContent.editedText") }})
        </span>
      </template>
    </OrganizationCardDense>
    <UserCard
      v-else-if="commentUser"
      :user="commentUser"
      plain
      :badges
      :frame
      class="col-span-2 !py-0 mr-8"
    >
      <template
        #postDisplayName
        v-if="highlightAuthorIds?.includes(commentUser.id)"
      >
        <span
          class="text-xs uppercase font-bold text-white bg-nami-comi-blue px-1.5 rounded-full"
        >
          {{ highlightAuthorTag || "creator" }}
        </span>
      </template>
      <template #postUsername>
        <span class="text-neutral-500 mx-1">•</span>
        <p :title="creationReadableDate" class="text-neutral-500">
          {{ creationTimeAgo }}
        </p>
        <span
          v-if="comment.attributes.updatedAt"
          class="text-neutral-500 ml-1"
          :title="
            'Modified: ' + new Date(comment.attributes.updatedAt).toString()
          "
        >
          ({{ $t("components.feedCommentContent.editedText") }})
        </span>
      </template>
    </UserCard>
    <ContextMenuList
      defaultPosition="bottom-left"
      class="absolute right-0 top-0"
      :options="
        [
          {
            name: translate('components.feedCommentContent.contextMenuReport'),
            action: () => emitAction('report'),
            icon: IconFlag,
          },
          commentOrg
            ? null
            : {
                name: isPosterBlocked
                  ? translate(
                      'components.feedCommentContent.contextMenuUnblockUser',
                    )
                  : translate(
                      'components.feedCommentContent.contextMenuBlockUser',
                    ),
                action: () => {
                  toggleBlockAction.action(commentUser!.id);
                },
                icon: IconForbid,
              },
          canEdit && {
            name: translate('components.feedCommentContent.contextMenuEdit'),
            icon: IconPencil,
            action: initiateEdit,
          },
          canDelete && {
            name: translate('components.feedCommentContent.contextMenuDelete'),
            icon: IconTrash,
            action: () => emitAction('delete'),
          },
          canRestore && {
            name: translate('components.feedCommentContent.contextMenuRestore'),
            icon: IconRotate,
            action: () => emit('forceLoad', comment),
          },
        ].filter(<T,>(x: T | boolean): x is T => !!x) as ContextMenuOption[]
      "
    >
      <template v-slot="{ isOpen, open, close }">
        <NamiButton
          @buttonClick="() => (isOpen ? close() : open())"
          button-type="secondary"
          :icon="IconDots"
          pill
          small
          noWaves
          :text="!isOpen"
        />
      </template>
    </ContextMenuList>
    <div class="col-start-2 col-end-2 mt-1">
      <div
        v-if="!editing"
        class="relative"
        :class="{
          'min-h-[4rem]': comment.attributes.spoiler && !overrideSpoiler,
        }"
      >
        <div
          v-if="comment.attributes.spoiler && !overrideSpoiler"
          class="cursor-pointer transition-all bg-neutral-500 group text-lg absolute inset-0 flex flex-col items-center justify-center rounded"
          @click.prevent.stop="overrideSpoiler = true"
        >
          <div
            class="text-sm sm:text-base bg-neutral-100/80 dark:bg-neutral-800/80 transition-all px-4 py-1 rounded-full group-hover:bg-neutral-100 group-hover:dark:bg-neutral-800 font-medium tracking-wider uppercase"
          >
            {{ $t("components.feedCommentContent.spoilerText") }}
          </div>
        </div>
        <ClientOnly>
          <ShowMore class="mb-2">
            <MarkdownRender
              class="text-sm sm:text-base link-style"
              :class="{
                'text-red-700 dark:text-red-400': comment.attributes.deletedAt,
                'text-neutral-800 dark:text-neutral-200':
                  !comment.attributes.deletedAt,
              }"
              :text="
                comment.attributes.deletedAt
                  ? 'Message was deleted.'
                  : commentMessageWithoutStickers
              "
              link-source="c"
            />
            <!--          TODO: this was for tagging other people-->
            <!--          <template v-for="token in htmlMessage">-->
            <!--            <span v-if="token.html" v-html="token.token"/>-->
            <!--            <span v-else>{{token.token}}</span>-->
            <!--          </template>-->
          </ShowMore>
          <button v-if="firstSticker" class="h-40 w-40">
            <AsyncImage
              v-if="firstSticker"
              class="max-h-40 max-w-40 h-40 w-40"
              :src="firstSticker.url"
              :alt="firstSticker.key"
            />
            <span hidden class="emote-id">{{ firstSticker.id }}</span>
          </button>
        </ClientOnly>
        <div v-if="comment.attributes.media" class="mb-3 flex">
          <AsyncImage
            class="max-h-[12rem] max-w-full rounded cursor-pointer"
            :src="
              getImageUrl(
                comment.id,
                'comment',
                'media',
                comment.attributes.media,
                '720',
              )
            "
            @click="visibleRef = true"
          />
        </div>
      </div>
      <div v-else>
        <div
          :class="{
            'bg-neutral-300 dark:bg-neutral-700 rounded':
              editTemplate.template.spoiler,
          }"
        >
          <ClientOnly>
            <LazyNamiRichTextEditor
              :model-value="editTemplate.template.message ?? ''"
              @update:model-value="(v) => (editTemplate.template.message = v)"
              :max-stickers="1"
              close-on-sticker-input
              ref="richTextEditor"
            >
              <template #toolbarBefore>
                <NamiButton
                  button-type="primary"
                  :icon="IconPhotoPlus"
                  small
                  pill
                  text
                  @buttonClick="doAddFile"
                  :disabled="
                    isPerformingAction ||
                    !!editTemplate.template.media ||
                    new Date(comment.attributes.createdAt).valueOf() <
                      Date.now() - 5 * 1000 * 60
                  "
                />
              </template>
            </LazyNamiRichTextEditor>
          </ClientOnly>
          <div
            v-if="editTemplate.template.media"
            class="flex flex-col min-w-0 relative"
            :class="{ disabled: isPerformingAction }"
          >
            <div
              class="relative min-h-[8rem] min-w-[8rem] max-h-[8rem] max-w-[8rem]"
            >
              <NamiFile
                class="!absolute top-0 h-full w-full left-0"
                :class="{
                  disabled: isPerformingAction,
                  'brightness-75': editTemplate.template.spoiler,
                }"
                :blob-url="editTemplate.template.media.blobUrl"
                :filename="editTemplate.template.media.filename"
                :no-delete="
                  new Date(comment.attributes.createdAt).valueOf() <
                  Date.now() - 5 * 1000 * 60
                "
                @remove="clearAttachment"
                @re-crop="() => (visibleRef = true)"
              />
            </div>
          </div>
        </div>
        <div class="flex space-x-2 mb-1 mt-2">
          <div class="mr-auto flex space-x-2">
            <Tooltip
              v-if="
                new Date(comment.attributes.createdAt).valueOf() <
                Date.now() - 5 * 1000 * 60
              "
              size="xlarge"
              variant="contrast"
              fixed
            >
              <template #tooltip>
                <div class="whitespace-pre-wrap">
                  {{ $t("components.feedCommentContent.cannotEditMediaText") }}
                </div>
              </template>
              <IconInfoCircle class="cursor-pointer w-8 h-8 p-1" />
            </Tooltip>
          </div>
          <input
            type="file"
            accept=".jpg,.jpeg,.gif,.png,.JPG,.JPEG,.GIF,.PNG"
            multiple
            hidden
            ref="fileInput"
            @change="updateFiles"
          />
          <NamiButton
            buttonType="secondary"
            small
            pill
            @buttonClick="cancelEdit"
          >
            <span class="px-1">{{
              $t("components.feedCommentContent.buttonCancelText")
            }}</span>
          </NamiButton>
          <NamiButton
            buttonType="primary"
            pill
            small
            @buttonClick="doPostEdit"
            :disabled="
              (editTemplate.template.message === comment.attributes.message &&
                !comment.attributes.media &&
                !editTemplate.template.media) ||
              editTemplate.template.media?.source === 'remote'
            "
          >
            <span class="px-1 flex items-center">
              <span class="mr-1">{{
                $t("components.feedCommentContent.buttonEditText")
              }}</span>
              <IconPencil :size="18" />
            </span>
          </NamiButton>
        </div>
      </div>
      <div
        class="flex gap-2 items-center cursor-pointer"
        @click="showReactionsModal = true"
      >
        <ul class="flex gap-1">
          <li v-for="name in Object.keys(reactionsPreview)">
            <AsyncImage
              :src="getAbsoluteAssetLink(`nami/reactions/${name}.png`)"
              class="w-4 h-4"
            />
          </li>
        </ul>
        <span v-if="totalReactions > 0" class="text-xs sm:text-sm font-medium">
          {{ totalReactions }}
        </span>
      </div>
      <slot name="options">
        <!-- Comment actions -->
        <div v-if="!editing" class="flex space-x-2 -ml-1">
          <FeedPostReactionButton
            :userReaction="commentMeta?.userReaction ?? null"
            @addReaction="doReaction"
            @removeReaction="doRemoveReaction"
            small
            :disabled="disableActions || !hasContent || !initialized"
          />
          <NamiButton
            buttonType="secondary"
            :icon="IconMessagePlus"
            small
            text
            :disabled="disableActions || !hasContent || disableReplyActions"
            @buttonClick="initiateReply"
          />
        </div>
      </slot>
    </div>
    <TransitionAutoHeight
      class="col-start-1 col-end-3 row-start-3 row-end-3"
      :model-value="replyWindowOpen"
    >
      <!-- Replying to comment textarea -->
      <FeedCommentAddForm
        class="pt-1"
        :user="userEntity"
        :feed-id="feedId"
        :feed-type="feedType"
        :parent-id="props.parentId ?? comment.id"
        sync
        @comment="
          (c) => {
            emit('postReply', c);
            replyWindowOpen = false;
          }
        "
      >
        <template #additionalActions="{ isPerformingAction: shouldDisable }">
          <div class="mr-2">
            <NamiButton
              buttonType="secondary"
              small
              pill
              @buttonClick="cancelReply"
              :disabled="shouldDisable"
            >
              <span class="px-1">{{
                $t("components.feedCommentContent.buttonCancelText")
              }}</span>
            </NamiButton>
          </div>
        </template>
      </FeedCommentAddForm>
    </TransitionAutoHeight>
    <div
      v-if="$slots.replies || showReplyLine || replyWindowOpen"
      class="col-start-1 col-end-1 row-start-2 row-end-2 pt-2"
    >
      <div
        class="bg-neutral-300 dark:bg-neutral-700 w-[2px] lg:w-[4px] h-full mx-auto rounded-t-full"
      ></div>
    </div>
    <div v-if="$slots.replies" class="col-start-1 col-end-3">
      <TransitionAutoHeight :model-value="showReplies">
        <div class="">
          <slot name="replies"></slot>
        </div>
      </TransitionAutoHeight>
      <div class="flex">
        <div class="w-10 grid grid-rows-2">
          <div
            class="bg-neutral-300 dark:bg-neutral-700 w-[2px] lg:w-[4px] h-full mx-auto"
          ></div>
          <div class="pl-[calc(50%-1px)] lg:pl-[calc(50%-2px)]">
            <div
              class="bg-neutral-300 dark:bg-neutral-700 h-[2px] lg:h-[4px] mr-2 rounded-b-full rounded-tr-full"
            ></div>
          </div>
        </div>
        <slot
          name="showReplies"
          v-bind="{ toggleReplies: () => (showReplies = !showReplies) }"
        >
          <NamiButton
            buttonType="primary"
            text
            small
            pill
            :icon="showReplies ? IconChevronUp : IconChevronDown"
            @buttonClick="() => (showReplies = !showReplies)"
            :button-text="
              showReplies
                ? $t(
                    'components.feed.feedCommentsContent.hideReplies',
                    totalReplies,
                  )
                : $t(
                    'components.feed.feedCommentsContent.showReplies',
                    totalReplies,
                  )
            "
          />
        </slot>
      </div>
    </div>
    <FeedPostReactionsModal
      v-model="showReactionsModal"
      :entityId="comment.id"
      entityType="comment"
    />
    <VueEasyLightbox
      v-if="editing || comment.attributes.media"
      teleport="body"
      :visible="visibleRef"
      :imgs="[
        editTemplate.template.media?.blobUrl ??
          getImageUrl(
            comment.id,
            'comment',
            'media',
            comment.attributes.media,
          ) ??
          '',
      ]"
      :index="0"
      @hide="visibleRef = false"
      rotate-disabled
    />
  </div>
</template>

<script setup lang="ts">
import {
  IconChevronDown,
  IconChevronUp,
  IconDots,
  IconFlag,
  IconForbid,
  IconInfoCircle,
  IconMessagePlus,
  IconPencil,
  IconPhotoPlus,
  IconRotate,
  IconTrash,
} from "@tabler/icons-vue";
import {
  type CommentableResource,
  type CommentEntity,
  getRelationship,
  isBadge,
  isComment,
  isExpanded,
  isFrame,
  isOrg,
  isUser,
  Reaction,
  type ReactionType,
  type UserEntity,
} from "~/src/api";
import { CommentTemplate } from "~/utils/templates/comment";
import { useCommentMeta } from "~/composables/comment/useCommentMeta";
import { nanoid } from "nanoid";
import VueEasyLightbox from "vue-easy-lightbox";
import type NamiRichTextEditor from "~/components/nami/NamiRichTextEditor.vue";
import { useBlockedUsers } from "~/composables/async/block";
import type { ContextMenuOption } from "~/components/contextMenu/ContextMenuList.vue";

interface Emits {
  (e: "postReply", v: CommentEntity): void;

  (e: "update", v: CommentEntity): void;

  (e: "delete", v: CommentEntity): void;

  (e: "report", v: CommentEntity): void;

  (e: "forceLoad", v: CommentEntity): void;

  (e: "updateReaction", v: ReactionType | null): void;
}

interface Props {
  comment: CommentEntity;
  feedType: CommentableResource;
  feedId: string;
  parentId?: string;
  replies?: number;
  disableActions?: boolean;
  disableReplyActions?: boolean;
  showReplyLine?: boolean;
  highlightAuthorIds?: string[];
  highlightAuthorTag?: string;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const nuxtApp = useNuxtApp();
const translate = nuxtApp.$i18n.global.t;
const authStore = nuxtApp.$auth();
const appStore = nuxtApp.$app();
const locale = nuxtApp.$i18n.global.locale;
const { blockedUsers, toggleBlockAction } = useBlockedUsers();
const { getImageUrl } = useMediaLink();

const hasContent = computed(
  () => !!props.comment.attributes.message || !!props.comment.attributes.media,
);

const richTextEditor = ref<InstanceType<typeof NamiRichTextEditor>>();

// providing bogus feedId/type here is ok since its edit only
const editTemplate = reactive(
  new CommentTemplate(
    props.feedType ?? "title",
    props.feedId ?? "unknown",
    props.comment,
  ),
);

const { init, initialized, commentMeta, updateCommentMeta } = useCommentMeta(
  props.comment.id,
);

const stickerRegex = /<sticker:(?<id>[^:]*):(?<key>[^:]*):(?<fileName>[^:]*)>/;
const stickerRegexGlobal =
  /<sticker:(?<id>[^:]*):(?<key>[^:]*):(?<fileName>[^:]*)>/g;

const emoteRegex = /<sticker:(?<id>[^:]*):(?<key>[^:]*):(?<fileName>[^:]*)>/;
const emoteRegexGlobal =
  /<sticker:(?<id>[^:]*):(?<key>[^:]*):(?<fileName>[^:]*)>/g;

const commentMessageWithoutStickers = computed(() => {
  return props.comment.attributes.message
    ?.replaceAll(stickerRegexGlobal, "")
    .trim();
});

const firstSticker = computed(() => {
  const foundStickerMatch =
    props.comment.attributes.message?.match(stickerRegex);
  if (!foundStickerMatch) return null;
  const { id, fileName, key } = foundStickerMatch.groups!;
  return {
    url: getImageUrl(id, "emote", "sticker", fileName),
    key: key,
    id: id,
  };
});

const adjusted = computed(
  // the replace should preserve all line breaks
  () =>
    props.comment.attributes.message?.replaceAll(/(\n(?=\n))+/g, "\n<br>\n") ??
    "",
);
const emoteOnly = computed(
  () => !adjusted.value.replaceAll(emoteRegexGlobal, "").trim(),
);

const reactionsPreview = computed<{ [key in ReactionType]?: number }>(() => {
  return Object.fromEntries(
    Object.entries(commentMeta.value?.allReactions ?? {})
      .filter(([, total]) => total > 0)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3),
  );
});

const totalReactions = computed<number>(() => {
  if (Object.values(commentMeta.value?.allReactions ?? {}).length === 0) {
    return 0;
  } else {
    return Object.values(commentMeta.value?.allReactions ?? {}).reduce(
      (a, b) => a + b,
      0,
    );
  }
});

onMounted(() => {
  init();
});

const formatTimeAgo = useTimeAgo(locale);
const formatDate = useDateFormatter(locale);

const creationReadableDate = formatDate(
  new Date(props.comment.attributes.createdAt),
);
const creationTimeAgo = formatTimeAgo.value(
  new Date(props.comment.attributes.createdAt),
  "mini",
);

const user = computed(() => authStore?.user);
const userEntity = computed<UserEntity | undefined>(
  () => authStore?.userEntity ?? undefined,
);
const totalReplies = computed(() =>
  Math.max(
    props.replies ?? 0,
    props.comment.relationships.filter(isComment).length,
  ),
);

// get author related info
const commentUser = computed(() => props.comment.relationships.find(isUser));
const commentOrg = computed(() => props.comment.relationships.find(isOrg));
const frame = computed(() => {
  const found = props.comment.relationships.find(isFrame);
  if (found && isExpanded(found)) return found;
});
const badges = computed(() =>
  props.comment.relationships.filter(isBadge).filter(isExpanded),
);

const isPosterBlocked = computed(() => {
  return blockedUsers.value.some((u) => commentUser.value?.id === u.id);
});

const overrideSpoiler = ref(false);

const canEdit = computed(
  () =>
    // Only non-deleted comments can be edited
    props.comment.attributes.message !== null &&
    // Staff are superusers
    (hasAdminAccess(user.value) ||
      // If logged user id matches the comment poster ID
      getRelationship(props.comment, "user").id === user.value?.profile.sub),
);

const canDelete = computed(
  () =>
    // Only non-deleted comments can be deleted
    props.comment.attributes.message !== null &&
    // Staff are superusers
    (hasAdminAccess(user.value) ||
      // If logged user id matches the comment poster ID
      getRelationship(props.comment, "user").id === user.value?.profile.sub),
);

const canRestore = computed(
  () =>
    // Only deleted comments can be restored
    props.comment.attributes.message == null &&
    // Only staff can restore deleted comments
    hasAdminAccess(user.value),
);

const emitAction = (e: "delete" | "report") => {
  replyWindowOpen.value = false;
  editing.value = false;
  // @ts-expect-error This is unknown why typescript fails
  emit(e, props.comment);
};

// for replies
const showReplies = ref(true);
const showReactionsModal = ref(false);

const replyWindowOpen = ref(false);

const initiateReply = () => {
  replyWindowOpen.value = true;
  editing.value = false;
};

const cancelReply = () => {
  replyWindowOpen.value = false;
};

// for edit
const editing = ref(false);
const fileInput = ref<HTMLInputElement>();
const { isPerformingAction, endAction, startAction } = useAction();
const uploadProgress = ref(0);
const visibleRef = ref(false);

function doAddFile() {
  if (editTemplate.template.media) return;
  fileInput.value?.click();
}

function updateFiles() {
  const inputElement = fileInput.value;
  if (!inputElement) throw new Error("Could not find input element.");

  const files = Array.from(inputElement.files ?? []);
  if (files.length === 0) return;

  const newFile = files[0];
  const localId = nanoid();

  editTemplate.template.media = {
    source: "local",
    filename: newFile.name,
    data: newFile,
    blobUrl: URL.createObjectURL(newFile),
    index: 0,
    id: localId,
    internalId: localId,
    progress: 0,
    failed: false,
  };

  inputElement.value = "";
}

const clearAttachment = () => {
  if (!editTemplate.template.media) return;
  URL.revokeObjectURL(editTemplate.template.media.blobUrl);
  editTemplate.template.media = null;
};

const initiateEdit = () => {
  replyWindowOpen.value = false;
  editing.value = true;
  editTemplate.parseFromComment(props.comment);
};

const cancelEdit = () => {
  editing.value = false;
};
const doPostEdit = async () => {
  if (isPerformingAction.value) return;
  startAction();

  if (!authStore?.userEntity) {
    appStore?.openLoginRequiredModal();
    return endAction();
  }

  await executeWithNotificationOnError(async () => {
    const token = await getTokenOrThrow();
    const originalExpandedAuthor =
      editTemplate.initial?.relationships.find(isUser)!;
    const comment = await editTemplate.update(
      token,
      (e) => (uploadProgress.value = e.progress ?? 0),
    );

    richTextEditor.value?.reset();

    emit("update", {
      ...comment,
      relationships: comment.relationships
        .filter((r) => !isUser(r))
        .concat([originalExpandedAuthor]),
    });
  }).catch(() => {});

  editing.value = false;
  endAction();
};

const updateReactionAmounts = (
  reactionType: ReactionType,
  offsetAmount = 0,
  updateUser = true,
) => {
  if (!window) return;
  const userReaction = commentMeta.value?.userReaction!;
  const newData = {
    allReactions: {
      ...commentMeta.value?.allReactions,
      [reactionType]: Math.max(
        (commentMeta.value?.allReactions![reactionType] ?? 0) + offsetAmount,
        0,
      ),
    },
    userReaction: updateUser
      ? offsetAmount > 0
        ? reactionType
        : null
      : userReaction,
  };

  if (userReaction && offsetAmount > 0) {
    newData.allReactions[userReaction]!--;
  }

  updateCommentMeta(newData);
};

async function doReaction(reactionType: ReactionType) {
  emit("updateReaction", reactionType);
  updateReactionAmounts(reactionType, 1);

  executeWithNotificationOnError(async () => {
    const token = await authStore?.getToken();
    if (!token) {
      throw new Error("Not logged in.");
    }

    await Reaction.createReaction(
      "comment",
      props.comment.id,
      reactionType,
      token,
    );
  }).catch(() => {
    updateReactionAmounts(reactionType, -1);
  });
}

async function doRemoveReaction() {
  emit("updateReaction", null);
  const userReaction = commentMeta.value?.userReaction!;
  updateReactionAmounts(userReaction, -1);

  executeWithNotificationOnError(async () => {
    const token = await authStore?.getToken();
    if (!token) {
      throw new Error("Not logged in.");
    }

    await Reaction.deleteReaction("comment", props.comment.id, token);
  }).catch(() => {
    updateReactionAmounts(userReaction, 1);
  });
}
</script>

<style scoped lang="postcss">
.link-style {
  :deep(a),
  a {
    @apply text-nami-comi-blue hover:underline;
  }
}
</style>
